import { lazy, memo } from 'react'

export type Props = {
  standalone?: boolean
}

const Default = lazy<React$ComponentType>(() => import('d2/components/Footer'))

const Standalone = lazy<React$ComponentType>(() => import('d2/components/Footer/standalone'))

const Footer = memo<Props>(({ standalone }) => (
  standalone ? <Standalone /> : <Default />
))

export default Footer
