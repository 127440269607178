// - -------------------------------------------------------------------- - //
// These values correspond with React Timeout Transition Group Times in variables.less
// - -------------------------------------------------------------------- - //

const transitionDurations = {

  'bundle-flip': {
    transitionEnterTimeout: 400,
    transitionLeaveTimeout: 150,
  },

  'composer-collapse': {
    transitionEnterTimeout: 400,
    transitionLeaveTimeout: 400,
  },

  flip: {
    transitionEnterTimeout: 400,
    transitionLeaveTimeout: 100,
  },

  'icon-slide': {
    transitionEnterTimeout: 400,
    transitionLeaveTimeout: 100,
  },

  'media-list-item': {
    transitionEnterTimeout: 100,
    transitionLeaveTimeout: 300,
  },

  'media-list-item-metadata': {
    transitionEnterTimeout: 400,
    transitionLeaveTimeout: 300,
  },

  overlay: {
    transitionEnterTimeout: 400,
    transitionLeaveTimeout: 120,
  },

} as const

export default function (name: keyof typeof transitionDurations) {
  const durations = transitionDurations[name]
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (durations) {
    return durations
  }

  throw new Error(`Invalid transition name: "${name}".`)
}
