import { noop } from 'lodash-es'

type RaygunOptions =
  | ['apiKey', string]
  | ['enableCrashReporting', boolean]
  | ['enablePulse', boolean]
  | ['groupingKey', AnyFunction]
  | ['logContentsOfXhrCalls', boolean]
  | ['noConflict', boolean]
  | ['options', any]
  | ['send', {
    customData: any,
    error: any,
    tags: string[]
  }]
  | ['setUser', any]
  | ['setVersion', string]
  | ['trackEvent', {
    path: string,
    type: 'pageView'
  }]
  | ['whitelistCrossOriginDomains', string[]]
  | ['withTags', string[]]

export type Raygun = (...args: RaygunOptions) => void

// eslint-disable-next-line unicorn/prefer-module, @typescript-eslint/no-require-imports
const rg4js: Raygun = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? noop : require('raygun4js')

export default rg4js

// export default () => {}
