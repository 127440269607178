/*
An Apollo Link that emits events before and after queries and mutations.
Currently does not support subscriptions because we don't use them.
TODO: open source this?
*/

// TODO: Tighten lint & types
/* eslint-disable sort-keys-fix/sort-keys-fix */

import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from 'apollo-link'
import { getMainDefinition } from '@apollo/client/utilities'
import { toString } from 'lodash-es'

const QUERY_OPERATION = 'query'
const MUTATION_OPERATION = 'mutation'

let requestNumber = 1

export default class ApolloLinkEvents extends ApolloLink {
  constructor (eventEmitter: any) {
    super()
    this.eventEmitter = eventEmitter
  }

  eventEmitter: any

  request (operation: Operation, forward?: NextLink | undefined): Observable<FetchResult> | null {
    const requestId: string = toString(requestNumber++)
    const definition = getMainDefinition(operation.query)
    const { operationName, variables } = operation
    // @ts-expect-error Property 'operation' does not exist on type 'OperationDefinitionNode | FragmentDefinitionNode'. Property 'operation' does not exist on type 'FragmentDefinitionNode'.ts(2339)
    const operationType = definition.operation
    switch (operationType) {
    case QUERY_OPERATION: {
      this.eventEmitter.emit('queryInit', {
        operationName,
        variables,
        requestId,
      })
      break
    }
    case MUTATION_OPERATION: {
      this.eventEmitter.emit('mutationInit', {
        operationName,
        variables,
        requestId,
      })
      break
    }
    default: { break
    }
    }
    return new Observable((observer) => {
      const sub = forward?.(operation).subscribe({
        next: (result: any) => {
          // @ts-expect-error Property 'operation' does not exist on type 'OperationDefinitionNode | FragmentDefinitionNode'. Property 'operation' does not exist on type 'FragmentDefinitionNode'.ts(2339)
          switch (definition.operation) {
          case QUERY_OPERATION: {
            this.eventEmitter.emit('queryResult', {
              operationName,
              variables,
              requestId,
              result,
            })
            break
          }
          case MUTATION_OPERATION: {
            this.eventEmitter.emit('mutationResult', {
              operationName,
              variables,
              requestId,
              result,
            })
            break
          }
          default: { break
          }
          }
          observer.next(result)
        },
        error: (networkError) => {
          // @ts-expect-error Property 'operation' does not exist on type 'OperationDefinitionNode | FragmentDefinitionNode'. Property 'operation' does not exist on type 'FragmentDefinitionNode'.ts(2339)
          // I'm unclear whether this should instead have given result: { errors: [networkerror] }
          switch (definition.operation) {
          case QUERY_OPERATION: {
            this.eventEmitter.emit('queryResult', {
              operationName,
              variables,
              requestId,
              networkError,
            })
            break
          }
          case MUTATION_OPERATION: {
            this.eventEmitter.emit('mutationResult', {
              operationName,
              variables,
              requestId,
              networkError,
            })
            break
          }
          default: { break
          }
          }
          observer.error(networkError)
        },
        complete: observer.complete.bind(observer),
      })

      return () => {
        sub?.unsubscribe()
      }
    })
  }
}
