/* eslint-disable */
//  This file was automatically generated and should not be edited.

export default {
  Organization: {
    user: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "User", id: args.id }),
  },
  Query: {
    album: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Album", id: args.id }),
    artist: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Artist", id: args.id }),
    composer: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Composer", id: args.id }),
    deal: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Deal", id: args.id }),
    geocountry: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Geocountry", id: args.id }),
    media: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Media", id: args.id }),
    merch_item: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "MerchItem", id: args.id }),
    organization: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Organization", id: args.id }),
    release: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Release", id: args.id }),
    storage_tier: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "StorageTier", id: args.id }),
    user: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "User", id: args.id }),
  },
  User: {
    album: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Album", id: args.id }),
    artist: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Artist", id: args.id }),
    media: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "Media", id: args.id }),
    payee_invite: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "PayeeInvite", id: args.id }),
    user: (_: any, args: any, { getCacheKey }: any) => getCacheKey({ __typename: "User", id: args.id }),
  },
}
