import turbolinks from 'turbolinks'

export const turbolinksEnabled = true // Toggle this boolean to enable/disable turbolinks, site-wide.

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (turbolinksEnabled) {
  turbolinks.start()
}

export { default } from 'turbolinks'
