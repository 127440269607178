import { turbolinksEnabled } from 'd2/vendor/turbolinks'
import $ from 'jquery'
let ready

if (turbolinksEnabled) {
  ready = function (callback) {
    document.addEventListener('turbolinks:load', callback)
  }
} else {
  ready = function (callback) {
    $(callback)
  }
}

export default ready
