import { lazy, memo } from 'react'
import type { OwnProps } from 'd2/components/MainNavBarD2/types'

export type Props = OwnProps & {
  standalone?: boolean
}

const Default = lazy<React$ComponentType<OwnProps>>(() => import('d2/components/MainNavBarD2'))

const Standalone = lazy<React$ComponentType<OwnProps>>(() => import('d2/components/MainNavBarD2/standalone'))

export default memo<Props>(({ d1, originalHTML, standalone }) => (
  standalone
    ? (
      <Standalone
        d1={d1}
        originalHTML={originalHTML}
      />
    )
    : (
      <Default
        d1={d1}
        originalHTML={originalHTML}
      />
    )
))
