import ready from '../utils/ready'

ready(() => {
  let isOpen = true

  function setIsOpen (bool) {
    isOpen = bool
    if (window.localStorage) {
      window.localStorage.setItem('vydia_ruby_console_is_open', bool ? 'true' : 'false')
    }
  }

  function getIsOpen () {
    if (window.localStorage) {
      isOpen = window.localStorage.getItem('vydia_ruby_console_is_open') !== 'false'
    }
    return isOpen
  }

  function resolveOpenState () {
    // find id "console" and hide it
    const consoleEl = document.getElementById('console')
    if (!consoleEl) {
      return
    }

    if (getIsOpen()) {
      consoleEl.style.display = 'block'
    } else {
      consoleEl.style.display = 'none'
    }
  }

  resolveOpenState()

  // find .close-button inside #console and hide it using vanilla js
  const closeButton = document.querySelector('#console .close-button')
  if (!closeButton) {
    return
  }

  closeButton.style.display = 'none'

  window.addEventListener('keydown', (e) => {
    if (e.key === '`') {
      // if e.target is an input field or text area, return
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        return
      }

      if (getIsOpen()) {
        setIsOpen(false)
      } else {
        setIsOpen(true)
      }
      resolveOpenState()
    } else if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
      // Clear console
      // else check if command K or control K is pressed
      // remove all .console-prompt-box (except the last one) and .console-message within .console-inner and remove them
      // The console hijacks events so you have to click outside the console and then press cmd k to clear it. Annoying
      // Open issue: https://github.com/rails/web-console/issues/286
      const prompts = Array.from(document.querySelectorAll('#console .console-inner .console-prompt-box')).slice(0, -2)
      prompts.forEach((item) => {
        item.remove()
      })

      const messages = Array.from(document.querySelectorAll('#console .console-inner .console-message'))
      messages.forEach((item) => {
        item.remove()
      })
    }
  })
})
